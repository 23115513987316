import React, { useContext, useEffect, useState } from 'react';
import { getQueryString } from '../../../../helpers/url';
import { WelletContext } from '../../../../context/WelletContext';
import { fetchCampaignById, updateCampaign } from '../../../../services/campaigns/campaignsService';
import Header from '../../../../components/Common/Header/Header';
import { CircleSpinner } from 'react-spinners-kit';
import styles from './CampaignDetail.module.css';
import { formatDateDefault, formatDateLong, getLocale } from '../../../../helpers/dates';
import { useSelector } from 'react-redux';
import ContentApp from '../../../../components/ContentApp';
import CopyIcon from '../../../../components/icons/copyIcon';
import { useTranslation } from 'react-i18next';
import AnalyticsIcon from '../../../../components/icons/AnalyticsIcon';
import InputFormBorder from '../../../../components/Common/InputFormBorder/InputFormBorder';
import LinkIcon from '../../../../components/icons/LinkIcon';
import Line from '../../../../components/Common/Line/Line';
import PlayIcon from '../../../../components/icons/PlayIcon';
import DownloadIcon from '../../../../components/icons/DownloadIcon';
import { download } from '../../../../helpers/file';
import CalendarFilterIcon from '../../../../components/icons/CalendarFilterIcon';
import ButtonProccessing from '../../../../components/Common/ButtonProccessing/ButtonProccessing';
import GenerateQr from '../GenerateQr/GenerateQr';
// import HelpsSlider from '../../../../components/HelpsSliders/HelpsSliders';

const CampaignDetail = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const id = getQueryString('id');
  const { t } = useTranslation();
  const name = getQueryString('name');
  const type = getQueryString('type');
  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const locale = getLocale(app.language);
  const [copied, setCopied] = useState(null);
  const [copiedText, setCopiedText] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);

  const [campaignName, setCampaignName] = useState('');
  const [campaignNamePlaceholder, setCampaignNamePlaceholder] = useState('');

  const [campaignDescription, setCampaignDescription] = useState('');
  const [campaignDescriptionPlaceholder, setCampaignDescriptionPlaceholder] = useState('');
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);
  const [copiedHashtag, setCopiedHashtag] = useState(null);
  const [inputError, setInputError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const getCampaignById = async () => {
    setLoading(true);
    try {
      const response = await fetchCampaignById(welletContext, 'es', id);
      const campaign = response.data;
      setCampaignInfo(campaign);
      setCampaignName(campaign.campaignName);
      setCampaignDescription(campaign.campaignDescription);
      setCampaignNamePlaceholder(campaign.campaignName);
      setCampaignDescriptionPlaceholder(campaign.campaignDescription);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCampaignById();
  }, []);

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const manualCopyText = (link) => {
    navigator.clipboard.writeText(link);
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 2000);
  };

  const manualCopyHashtags = (link) => {
    navigator.clipboard.writeText(link);
    setCopiedHashtag(true);
    setTimeout(() => {
      setCopiedHashtag(false);
    }, 2000);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    if (!campaignName) {
      setInputError(t('campaignError.name'));
      return;
    }

    if (!campaignDescription) {
      setDescriptionError(t('campaignError.description'));
      return;
    }
    setBtnStatus(ButtonStatus.InProgress);
    try {
      await updateCampaign(welletContext, {
        Id: id,
        Name: campaignName,
        Description: campaignDescription,
        ToDateUtc: null,
        FromDateUtc: null,
      });

      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        history.push('/my-campaigns');
      }, 1500);
    } catch (error) {
      setBtnStatus(ButtonStatus.Error);
      console.error(error);
    }
  };

  const card = (campaign) => {
    return (
      <div className={styles.campaignCard}>
        <div className={styles.containerImg}>
          <img src={campaign.logo || campaign.showLogo} alt='logo' className={styles.logoImg} />
        </div>

        <div className='col'>
          <div className='bold'> {campaign.showName}</div>
          <div>
            {t(campaignInfo.campaignType)} {app.language === 'es' ? 'de' : null}{' '}
            {campaign.networkCampaignName}
          </div>

          <div className='text-secondary font-small mt-2'>
            {t('target')}: <span className='bold'>{t('sales')}</span>
          </div>

          <div className='text-secondary font-extra-small mt-1'>
            {t('createdDate')}: {formatDateLong(campaign.createdDateTime, locale)}
          </div>
        </div>
      </div>
    );
  };

  const [info, setInfo] = useState({
    campaignName: campaignNamePlaceholder,
    campaignDescription: campaignDescriptionPlaceholder,
    campaignDate: {
      from: null,
      to: null,
    },
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e);
    setInfo({
      ...info,
      [name]: value,
    });
    console.log(info);
    if (name === 'campaignName' && !value) {
      console.log(name);
      console.log(value);
      setInputError(t('campaignError.name'));
    } else {
      setInputError(null);
    }

    if (name === 'campaignDescription' && !value) {
      setDescriptionError(t('campaignError.description'));
    } else {
      setDescriptionError(null);
    }
  };
  const renderDownloadButton = (url, fileName) => {
    const isVideo = url.includes('.mp4');
    const fileExtension = isVideo ? 'mp4' : 'jpg';
    const fullFileName = `${fileName}.${fileExtension}`;
    const downloadUrl = ios
      ? `https://wr-scope.wellet.dev/#descargar?url=${url}&filename=${fullFileName}`
      : null;

    return (
      <div className='text-primary mt-2 text-decoration-none'>
        {downloadUrl ? (
          <a href={downloadUrl} target='_blank' rel='noopener noreferrer'>
            {t('download')}{' '}
            <DownloadIcon
              size={18}
              color={'var(--color-primary)'}
              style={{ marginLeft: '.5rem', marginTop: '-2px' }}
            />
          </a>
        ) : (
          <div onClick={() => download(url, fullFileName)}>
            {t('download')}{' '}
            <DownloadIcon
              size={18}
              color={'var(--color-primary)'}
              style={{ marginLeft: '.5rem', marginTop: '-2px' }}
            />
          </div>
        )}
      </div>
    );
  };

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const handleRedirectAnalytics = (id) => {
    history.push(`/analytics-details?id=${id}`);
  };

  return (
    <div>
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <>
          <Header
            pathName='/my-campaigns'
            title={campaignInfo.campaignName}
            rightIcon={<AnalyticsIcon size={30} />}
            onClickRight={() => handleRedirectAnalytics(id)}
          />
          <ContentApp>
            <form onSubmit={sendForm}>
              {card(campaignInfo)}
              <div className='position-relative '>
                <div>
                  <InputFormBorder
                    name='campaignName'
                    type='text'
                    label=''
                    value={campaignName}
                    placeholder={campaignNamePlaceholder}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCampaignName(e.target.value);
                      setCampaignNamePlaceholder(
                        e.target.value === '' ? '' : campaignInfo.campaignName,
                      );
                    }}
                    isDisabled={!isEditing}
                    border={'none'}
                    handleEdit={() => setIsEditing(!isEditing)}
                    customClass={`mt-4 px-2 ${isEditing ? '' : 'input-create-campaign'}`}
                    editInput
                    classInput={isEditing && 'editing-text-input'}
                  />
                  {inputError && <div className='errorLabelCampaign mt-2 '>{inputError}</div>}
                </div>

                <div className={`${styles.descriptionLabel} bold text-secondary `}>
                  {t('campaign.campaignName')}
                </div>
              </div>
              <div className='position-relative bold'>
                <div>
                  <InputFormBorder
                    isTextArea
                    name='campaignDescription'
                    type='text'
                    label=''
                    value={campaignDescription}
                    placeholder={campaignDescriptionPlaceholder}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCampaignDescription(e.target.value);
                      setCampaignDescriptionPlaceholder(
                        e.target.value === '' ? '' : campaignInfo.campaignDescription,
                      );
                    }}
                    isDisabled={!isEditingDescription}
                    classInput={isEditingDescription && 'editing-text-input'}
                    border={'none'}
                    handleEdit={() => setIsEditingDescription(!isEditingDescription)}
                    customClass={`mt-4 px-2  ${isEditingDescription ? '' : 'editing-text-input'}`}
                    editInput
                  />
                  {descriptionError && (
                    <div className='errorLabelCampaign mt-2'>{descriptionError}</div>
                  )}
                </div>

                <div className={`${styles.descriptionLabel} bold text-secondary `}>
                  {t('description')}
                </div>
              </div>

              <div className={styles.campaignLengthButton}>
                <div>{t('campaignDuration')}</div>
                <CalendarFilterIcon size={25} />
                <div className='ml-2 fw-semibold'>
                  {campaignInfo.fromDate && campaignInfo.toDate ? (
                    <>
                      {formatDateDefault(campaignInfo?.fromDate, locale)} -{' '}
                      {formatDateDefault(campaignInfo?.toDate, locale)}
                    </>
                  ) : (
                    t('campaign.selectDateRange')
                  )}
                </div>
                {campaignInfo.fromDate && campaignInfo.toDate && (
                  <div className={styles.active}>{t('active')}</div>
                )}
              </div>

              {campaignInfo.campaignType === 'QR_DIGITAL' ||
              campaignInfo.campaignType === 'QR_PRINTED' ? (
                <div className='card p-3 mt-2 fw-semibold'>
                  {t('codigoQr')}
                  <Line color='#212121' className='my-3' />
                  <div className='font-smaller text-secondary '>{t('downloadAndPrintQrcode')}</div>

                  <GenerateQr type={campaignInfo} data={campaignInfo}></GenerateQr>
                </div>
              ) : (
                <div>
                  <div className='bold mt-4 row col'>
                    <LinkIcon color='var(--color-deep-gray)' size={17} />
                    <div className='ml-2'>{t('link')}</div>
                  </div>
                  <div
                    className='p-2 mt-2 text-truncate font-medium bold d-flex align-items-center justify-space-between'
                    style={{
                      backgroundColor: 'var(--color-primary)',
                      color: '#000',
                      borderRadius: '10px',
                    }}
                  >
                    <div className='text-truncate col'>{campaignInfo.link}</div>
                    <div className='col-auto pl-0' onClick={() => onCopy(campaignInfo.link)}>
                      <CopyIcon fillColor='#000' size='28px' />
                    </div>
                    {copied && (
                      <div className='text-center mt-1'>
                        <div className='alert alert-info copy-link mb-1' role='alert'>
                          {t('copied')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* <HelpsSlider type={campaignInfo.networkCampaignName} /> */}

              <div className='card mt-3 p-3'>
                {type !== 'Código QR ' ? (
                  <div className='bold'>{t('textLinkHashtag')}</div>
                ) : (
                  <div className='bold'>{t('suggestedText')}</div>
                )}

                <Line color='#212121' className='my-3' />
                <div className='font-small text-secondary'>{t('textLinkHashtagDescription')}</div>
                <div className={styles.hashTag}>
                  <div>{campaignInfo.suggestedText}</div>
                  <div
                    onClick={() => manualCopyText(campaignInfo.suggestedText)}
                    className={styles.copyIcon}
                  >
                    <CopyIcon size='28px' />
                  </div>
                  {copiedText && (
                    <div className='text-center mt-1'>
                      <div className='alert alert-info copy-link mb-1' role='alert'>
                        {t('copied')}
                      </div>
                    </div>
                  )}
                </div>

                {type !== 'Código QR ' ? (
                  <div>
                    <div className='bold mt-4'>{t('Hashtags')}</div>
                    <div className={styles.hashTag}>
                      <div>{campaignInfo.hashtag}</div>
                      <div
                        onClick={() => manualCopyHashtags(campaignInfo.hashtag)}
                        className={styles.copyIcon}
                      >
                        <CopyIcon size='28px' />
                      </div>
                      {copiedHashtag && (
                        <div className='text-center mt-1'>
                          <div className='alert alert-info copy-link mb-1' role='alert'>
                            {t('copied')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                <div className={styles.copyButton}>{t('copyAll')}</div>
              </div>
              {campaignInfo?.media?.length > 0 ? (
                <div className='card p-3 mt-2 fw-semibold'>
                  {t('Multimedia')}
                  <div className='row mt-3 align-items-center fw-semibold'>
                    {campaignInfo?.media?.map((c, index) => (
                      <div
                        key={index}
                        className={`col-12 mb-3 row align-items-center ${styles.mediaContainer}`}
                      >
                        <div className='col-auto'>
                          {c.mediaUrl.includes('.mp4') ? (
                            <div
                              className={`video-item ${styles.selectedVideo} position-relative`}
                              controls='controls'
                            >
                              <video preload='metadata'>
                                <source src={c.mediaUrl + '#t=0.1'} type='video/mp4' />
                              </video>
                              {c.mediaUrl.includes('.mp4') && (
                                <div className={styles.playButton}>
                                  <PlayIcon size='25px' color='#d3cfcc' />
                                </div>
                              )}
                            </div>
                          ) : (
                            <img className={styles.selectedMedia} src={c.mediaUrl} alt='media' />
                          )}
                        </div>
                        <div className='col'>
                          <div className='font-medium'>{c.title}</div>
                          {renderDownloadButton(
                            c.mediaUrl,
                            normalizeFileName(campaignInfo.campaignName),
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <ButtonProccessing
                onClick={sendForm}
                status={btnStatus}
                className='mt-2'
                text='Guardar'
                size='large'
                color='#000'
                backgroundColor='var(--color-primary)'
              />
              {/* <Button
                backgroundColor='#7E7E7E'
                className='mt-2'
                text='Eliminar campaña'
                size='large'
              /> */}
            </form>
          </ContentApp>
        </>
      )}
    </div>
  );
};

export default CampaignDetail;
