import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { format, parseISO } from 'date-fns';
import { getLocale } from '../../../helpers/dates';
import { useActivities } from '../../../hooks/useActivities';
import { SkeletonPoints } from '../../../components/Skeletons/SkeletonPoints';
import { getAssociateStatusAccount } from '../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import StatusTracker from '../../../components/Points/StatusTracker/StatusTracker';
import StatusCommissions from '../../../components/Points/StatusCommissions/StatusCommissions';
import Activity from '../../../components/Activities/Activity/Activity';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import styles from './pointsScreen.module.css';
import ActivitiesHome from '../../../components/Activities/ActivitiesHome/ActivitiesHome';
import HeaderUserPoints from '../../../components/Points/HeaderUserPoints';

const PointsScreen = ({ history }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.associates.user);
  const affiliatePoints = user.salesCategory.points;
  const categoryName = user.salesCategory.category;
  const expirationDate = user.salesCategory.expirationDate;
  const jwt = useSelector((state) => state.associates.jwt);
  const dispatch = useDispatch();
  //const newExpirationDate = new Date(expirationDate);
  const newExpirationDate = new Date('2025-12-31');
  const welletContext = useContext(WelletContext);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  const expirationDateFormatted = format(newExpirationDate, 'MM/yy', {
    locale,
  });

  const expirationYear = format(newExpirationDate, 'yyyy', {
    locale,
  });

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(`associates/getAllsalesCategory`);
      if (response.status === 200) {
        const sortedCategories = response.data.categoriesList.sort((a, b) => b.level - a.level);
        setCategories(sortedCategories);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAssociateStatusAccount(welletContext, jwt));
  }, [jwt]);

  const pointsInfoData = useActivities({
    pageSize: 5,
    endpoint: '/associates/getpoints',
    api: welletContext.apis.tickets,
  });

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
  }, []);

  return (
    <>
      <AccountHeader title={'Advantage Network'} icon='config' pathName='/configuration' />
      <ContentApp header menu>
        <div className='points'>
          <HeaderUserPoints
            user={user}
            expirationDate={expirationDateFormatted}
            expirationYear={expirationYear}
            category={categoryName}
            points={affiliatePoints}
          />
          {!loading ? (
            <>
              <div className={`card mt-2`}>
                <StatusTracker
                  title={t('leaderboard')}
                  points={Number(affiliatePoints)}
                  categories={categories}
                  loading={loading}
                  categoryName={categoryName}
                />
              </div>

              <div className='mt-2'>
                <ActivitiesHome
                  title={<div className='bold'>{t('recentAccountActivities')}</div>}
                  linkTitle={
                    pointsInfoData?.activities?.length === 5 ? t('allAccountActivity') : null
                  }
                  pathName={'/points-activities'}
                  history={history}
                  children={
                    pointsInfoData?.activities?.length === 0 ? (
                      <EmptyActivity
                        title={t('noPoints')}
                        subtitle={t('noPointsDescription')}
                        icon={'notification'}
                      />
                    ) : (
                      pointsInfoData?.activities?.map((p, index) => (
                        <Activity
                          key={index}
                          loading={pointsInfoData.loading}
                          hasMore={pointsInfoData.hasMore}
                          loadMore={pointsInfoData.loadMore}
                          title={`${t('pointsReservation')} ${p.referenceCode}`}
                          subtitle={<div className='text-secondary'>{p.showName}</div>}
                          item2={
                            <div className='bold'>{`${
                              p.points > 0 ? `+ ${p.points}` : `- ${Math.abs(p.points)}`
                            }`}</div>
                          }
                          item3={
                            <div className='text-secondary text-right'>
                              {format(parseISO(p.operationDate), 'dd MMM', {
                                locale,
                              })}
                            </div>
                          }
                          className={`${
                            index === pointsInfoData.activities.length - 1 ? '' : 'feed-item-border'
                          } ${styles.activity}`}
                        />
                      ))
                    )
                  }
                />
              </div>
              <div className='mt-2'>
                <StatusCommissions
                  categoryName={categoryName}
                  categories={categories}
                  title={t('commissionStatusTitle', {
                    organization: app.name ,
                  })}

                />
              </div>
            </>
          ) : (
            SkeletonPoints()
          )}
        </div>

        <CustomerNav history={history} />
      </ContentApp>
    </>
  );
};

export default PointsScreen;
