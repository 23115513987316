import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleCommission } from '../../../actions/associatesActions';
import ChevronIcon from '../../icons/chevronIcon';
import SearchIcon from '../../icons/SearchIcon';

const Header = ({
  title,
  showBack = true,
  showSwitch = false,
  statusShowSwitch = false,
  showClose = false,
  pathName,
  pathSearch,
  pathState,
  hasBackground = false,
  onClick = null,
  search = false,
  onSearch,
  rightIcon,
  rightText,
  onClickRight,
  absolute = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGoBack = () => {
    if (pathName) {
      history.push({
        pathname: pathName,
        search: pathSearch,
        state: pathState,
      });
    } else if (onClick) {
      onClick();
    } else {
      history.goBack();
    }
  };

  const handleClose = () => {
    window.location.href = '/';
  };

  const handleClick = () => {
    dispatch(toggleCommission());
  };

  return (
    <div
      className={`header app w-100 ${hasBackground ? 'transition-header' : ''} ${
        absolute ? 'position-absolute' : ''
      }`}
    >
      <div className='container '>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='row '>
              {showBack ? (
                <div className='col-auto back p-0'>
                  <button
                    type='button'
                    onClick={handleGoBack}
                    className='back-button font-medium'
                    aria-label='Close'
                  >
                    <ChevronIcon fillColor='var(--color-primary)' size={'26px'} rotation={180} />
                  </button>
                </div>
              ) : (
                <div className='col-auto p-0 back' style={{ minWidth: '55px' }}></div>
              )}

              <div
                className={
                  'col header-title bold' +
                  (!showSwitch && !showClose && !search
                    ? ' center'
                    : search
                    ? ' text-center'
                    : '') +
                  (!showBack ? ' mt-3' : ' my-auto')
                }
                style={{ color: 'var(--color-font-primary)', marginLeft: rightIcon ? '0px' : '' }}
              >
                {title}
              </div>
              {search && (
                <div className='col-auto my-auto search p-0 mr-3' onClick={onSearch}>
                  <SearchIcon size='24px' fillColor='var(--color-primary)' />
                </div>
              )}

              {showSwitch ? (
                <div className='col-auto pl-0 text-right my-auto'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      onClick={handleClick}
                      defaultChecked={statusShowSwitch}
                    />
                    <span className='slider round'></span>
                  </label>
                </div>
              ) : (
                ''
              )}
              {showClose ? (
                <div className='col-auto pl-0 text-right'>
                  <div className='close-page' onClick={handleClose}>
                    <div className='mdiv'>
                      <div className='md' />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {rightIcon && (
                <div className='col-auto my-auto' onClick={onClickRight}>
                  {rightIcon}
                </div>
              )}
              {rightText && (
                <div
                  style={{ color: 'var( --color-lighter-gray)' }}
                  onClick={onClickRight}
                  className={' col-auto my-auto  font-norma'}
                >
                  {rightText}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
