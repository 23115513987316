import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { useSelector } from 'react-redux';

const TermsAndConditions = () => {
  const app = useSelector((state) => state.app);
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white'>{t('termsAndConditions')}</div>
        <div className='text-secondary mt-3'>
          <p className='text-white bold'>{t('termsConditions.subtitle1')}</p>

          <p>
            {t('termsConditions.description1', {
              organization: app.name,
            })}
          </p>

          <p className='text-white bold'>{t('termsConditions.subtitle2')}</p>

          <p>
            {t('termsConditions.description2', {
              organization: app.name,
            })}
          </p>

          <p className='text-white bold'>{t('termsConditions.subtitle3')}</p>
          <ul>
            <li>{t('termsConditions.description3item1')}</li>
            <li>{t('termsConditions.description3item2')}</li>
            <li>{t('termsConditions.description3item3')}</li>
            <li>{t('termsConditions.description3item4')}</li>
            <li>{t('termsConditions.description3item5')}</li>
            <li>{t('termsConditions.description3item6')}</li>
            <li>{t('termsConditions.description3item7')}</li>
            <li>{t('termsConditions.description3item8')}</li>
          </ul>
          <p className='text-white bold'>{t('termsConditions.subtitle4')}</p>
          <ul>
            <li>{t('termsConditions.description4item1')}</li>
            <li>{t('termsConditions.description4item2')}</li>
          </ul>
          <p className='text-white bold'>{t('termsConditions.subtitle5')}</p>
          <ul>
            <li>{t('termsConditions.description5item1')}</li>
            <li>{t('termsConditions.description5item2')}</li>
            <li>{t('termsConditions.description5item3')}</li>
          </ul>
          <p className='text-white bold'>{t('termsConditions.subtitle6')}</p>
          <ul>
            <li>{t('termsConditions.description6item1')}</li>
            <li>{t('termsConditions.description6item2')}</li>
            <li>{t('termsConditions.description6item3')}</li>
          </ul>
          <p className='text-white bold'>{t('termsConditions.subtitle7')}</p>

          <p>
            {' '}
            {t('termsConditions.description7', {
              organization: app.name,
            })}
          </p>

          <p className='text-white bold'>{t('termsConditions.subtitle8')}</p>
          <p>
            {t('termsConditions.description8')}{' '}
            <a href='mailto:contactcenter@gruporosanegra.com.mx'>
              contactcenter@gruporosanegra.com.mx
            </a>
            .
          </p>

          <p className='text-white bold'>{t('termsConditions.subtitle9')}</p>
          <p>{t('termsConditions.description9')}</p>
        </div>
      </ContentApp>
    </>
  );
};

export default TermsAndConditions;
