export default (
  state = {
    status: 'LOADING',
    searchParams: '',
    language: '',
    tenantId: '',
    name: '',
    logoURL: '',
    imageURL: '',
    webURL: '',
    scopeOrganization: { organizationId: 83 },
    canRefresh: true,
    biometricAutomatic: '',
    biometricRequired: '',
    backGroundImageUrl: '',
    logoType: '',
    favIconUrl: '',
    titleWeb: '',
    countryCode:'',
  },
  action,
) => {
  switch (action.type) {
    case 'APP_READY':
      return { ...state, status: 'READY', language: action.payload };
    case 'SET_TENANT_ID':
      return { ...state, tenantId: action.payload };
    case 'SET_APP_DATA':
      return {
        ...state,
        name: action.payload.name,
        logoURL: action.payload.logoURL,
        imageURL: action.payload.imageURL,
        webURL: action.payload.webURL,
        biometricAutomatic: action.payload.biometricAutomatic,
        biometricRequired: action.payload.biometricRequired,
        backGroundImageUrl: action.payload.backGroundImageUrl,
        logoType: action.payload.logoType,
        favIconUrl: action.payload.favIconUrl,
        titleWeb: action.payload.titleWeb,
        countryCode: action.payload.countryCode,
      };
    case 'SET_SCOPE_ORGANIZATION':
      return {
        ...state,
        //scopeOrganization: action.payload,
      };
    case 'ASSOCIATES_LOGOUT':
      return {
        ...state,
        //scopeOrganization: null,
      };
    case 'SET_CAN_REFRESH':
      return { ...state, canRefresh: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    default:
      return state;
  }
};