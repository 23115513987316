import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getLocale, alignTimezone } from '../../../helpers/dates';
import { formatMoney } from '../../../helpers/format';
import { useTranslation } from 'react-i18next';
import { getCategory } from '../../../helpers/category';
import Accordion from '../../Common/Accordion/Accordion';
import AccordionStatus from '../AccordionStatus/AccordionStatus';
import styles from './statusTracker.module.css';

const StatusTracker = ({ title, points, categories, categoryName }) => {
  const { t } = useTranslation();
  const categoryId = useSelector((state) => state.associates.user.salesCategory.categoryId);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const qualifyBy = useSelector((state) => state.associates.user.salesCategory.fromDateUTC);
  const qualifyByFormatted = format(alignTimezone(qualifyBy), 'MMMM d, yyyy', { locale });

  const getStatus = (category) => {
    const categoryIndex = categories.findIndex((c) => c.id === category.id);
    const currentCategory = categories[categoryIndex];
    const nextCategory = categories[categoryIndex + 1];
    if (currentCategory.maxPoints === null) {
      return points >= currentCategory.targetPoints ? 'completed' : 'incompleted';
    }
    if (points === 0 && categoryIndex === categories.length - 1) {
      return 'inprogress';
    } else if (points >= currentCategory.targetPoints) {
      return 'completed';
    }
    if (nextCategory && points >= nextCategory.targetPoints && points > 0) {
      return 'inprogress';
    }
    return 'incompleted';
  };

  return (
    <div className='fade-up'>
      <div className='bold px-3 pt-3'>{title}</div>
      <div className=' text-secondary px-3 font-small'>
        {t('ratedSince')} <span className='text-capitalize'>{qualifyByFormatted}</span>
      </div>
      <div className='mt-3'>
        {categories?.map((category, index) => {
          const currentIndex = categories?.findIndex((c) => c.id === category.id);
          const inprogressIndex = categories?.findIndex((c) => c.id === categoryId);
          const inprogressCategory =
            categories[currentIndex > inprogressIndex ? inprogressIndex - 1 : currentIndex - 1];
          return (
            <div key={index}>
              <div
                className={`${styles.line} ${category.name === 'Presidente' ? '' : 'mx-2'}`}
              ></div>
              <AccordionStatus
                key={index}
                category={category}
                categoryName={categoryName}
                children={
                  categoryId === categories[0].id && category.id === categories[0].id ? (
                    <div className='bold mb-1 font-small'>{t('maxPoints')}</div>
                  ) : categoryId === categories[0].id && category.id <= categoryId ? (
                    <div className='pt-1 py-1'>
                      {category.maxPoints !== null && (
                        <div className='text-secondary mb-1 font-tiny'>{t('categoryAchieved')}</div>
                      )}
                    </div>
                  ) : category.id <= categoryId ? (
                    <div className='pt-1 py-1'>
                      <div>
                        <div className='text-secondary font-tiny'>{t('youNeed')}</div>
                        <div
                          className='semi-bold font-normal'
                          style={{ color: 'var(--color-font-primary)' }}
                        >
                          {formatMoney(
                            categories[
                              currentIndex > inprogressIndex
                                ? inprogressIndex - 1
                                : currentIndex - 1
                            ]?.targetPoints - points,
                            { decimals: 0, currencySymbol: '' },
                            true,
                          )}
                        </div>
                        <div className='text-secondary mb-1 tiny'>{t('morePoints')}:</div>
                        <span className={`${styles.affliatedCard} font-tiny bold p-1 mt-5`}>
                          Advantage {getCategory(inprogressCategory?.name, t)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className='pt-1'>
                      <div className='text-secondary font-tiny'>{t('youNeed')}</div>
                      <div
                        className='semi-bold font-normal'
                        style={{ color: 'var(--color-font-primary)' }}
                      >
                        {formatMoney(
                          category.targetPoints - points,
                          { decimals: 0, currencySymbol: '' },
                          true,
                        )}
                      </div>
                      <div className='text-secondary mb-2 font-tiny'>{t('morePoints')}:</div>
                      <span
                        className={`${styles.affliatedCard} text-secondary font-tiny semi-bold`}
                      >
                        Advantage{' '}
                        {getCategory(
                          getStatus(category) === 'inprogress'
                            ? inprogressCategory?.name
                            : category.name,
                          t,
                        )}
                      </span>
                    </div>
                  )
                }
                points={points}
                index={index}
                status={getStatus(category)}
              />
            </div>
          );
        })}
      </div>
      <Accordion
        title={t('infoStatus')}
        content={t('infoStatusDescription', {
          organization: app.name,
        })}
        type={'points'}
      />
      <Accordion
        title={t('pointsExpiration')}
        content={t('pointsExpirationDescription')}
        points={points}
        type={'points'}
      />
    </div>
  );
};
export default StatusTracker;
