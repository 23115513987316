import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import { WelletContext } from '../../../../context/WelletContext';
import ContentApp from '../../../../components/ContentApp';
import { CircleSpinner } from 'react-spinners-kit';
import Slider from '../../../../components/Common/Slider/Slider';
import {
  fetchCampaignsFeed,
  fetchCampaignsFeedShows,
  saveCampaign,
} from '../../../../services/campaigns/campaignsService';
import styles from './CampaignsFeed.module.css';
import CampaignMediaCard from '../../../../components/Common/CampaignMediaCard/CampaignMediaCard';
import { SkeletonCampaignFeed } from '../../../../components/Skeletons/SkeletonCreateCampaign';
import Rosanegra from '../../../../images/rosanegra-logo.png';
import { useTranslation } from 'react-i18next';
import EmptyActivity from '../../../../components/Common/EmptyActivity/EmptyActivity';
import { useSelector } from 'react-redux';

const CampaignsFeed = ({ history }) => {
  const { t } = useTranslation();
  const [shows, setShows] = useState(null);
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMedia, setLoadingMedia] = useState(true);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const sliderRef = useRef();

  const getData = async (showId = '') => {
    const body = {
      ShowId: showId,
      language,
    };

    const responseMedia = await fetchCampaignsFeed(welletContext, body);
    setMedia(responseMedia.data);
    setLoadingMedia(false);
  };

  const getShows = async () => {
    const responseShows = await fetchCampaignsFeedShows(welletContext);
    setShows(responseShows.data);
    setLoading(false);
  };

  const handleSaveCampaign = async (id) => {
    try {
      const response = await saveCampaign(welletContext, id);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByShow = (showId) => {
    setLoadingMedia(true);
    setShows((prevShows) =>
      prevShows.map((s) => (s.showId === showId ? { ...s, visited: true } : s)),
    );
    getData(showId);
  };

  useEffect(() => {
    getData();
    getShows();
  }, []);

  return (
    <div>
      <Header title={t('commissionableLinks')} />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <ContentApp>
          {shows.length > 0 ? (
            <>
              <Slider
                ref={sliderRef}
                slideSpacing={10}
                initialSlide={0}
                touchScroll={true}
                showBtns={false}
                visibleSlides={'all'}
              >
                <div
                  className='text-center text-secondary font-extra-small'
                  onClick={() => handleFilterByShow('')}
                >
                  <img className={styles.allLogo} src={Rosanegra} alt='rosanegra-logo' />
                  <div className='mt-2'>{t('all')}</div>
                </div>
                {shows?.map((s, index) => (
                  <div
                    className='d-flex flex-column  text-secondary font-extra-small text-center align-items-center'
                    key={index}
                    onClick={() => handleFilterByShow(s.showId)}
                  >
                    <div className={`${s.visited ? styles.containerImg : styles.logoBorder}`}>
                      <img className={styles.restaurantImg} src={s.showLogo} alt='logo' />
                    </div>
                    <div className='mt-2'>{s.showName}</div>
                  </div>
                ))}
              </Slider>
              {loadingMedia ? (
                SkeletonCampaignFeed()
              ) : (
                <>
                  {media.map((m, index) => (
                    <CampaignMediaCard
                      key={index}
                      campaign={m}
                      index={index}
                      handleSaveCampaign={() => handleSaveCampaign(m.associateCampaignId)}
                      history={history}
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <EmptyActivity title={t('noCampaigns')} />
          )}
        </ContentApp>
      )}
    </div>
  );
};

export default CampaignsFeed;
