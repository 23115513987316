import React, { useContext, useEffect, useRef, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryString } from '../../helpers/url';
import {
  skeletonEmptyPerformances,
  skeletonSchedule,
} from '../../components/Skeletons/SkeletonRestaurantReservation';
import { generateDisabledDates, getLocale } from '../../helpers/dates';
import { useHistory } from 'react-router-dom';
import { convertTime12to24 } from '../../helpers/dates';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { setReservation } from '../../actions/reservationActions';
import Header from '../../components/Common/Header/Header';
import ReservationSelector from '../../components/ReservationSelector/ReservationSelector';
import ContentApp from '../../components/ContentApp';
import ProductPerformanceSelector from '../../components/ProductPerformanceSelector/ProductPerformanceSelector';
import EmptyActivity from '../../components/Common/EmptyActivity/EmptyActivity';
import Calendar from '../../components/Common/Calendar/Calendar';
import Button from '../../components/Common/Button/Button';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import styles from './reservation.module.css';
import '../../css/product-list2.css';
import ProductModal from '../../components/Common/ProductModal/ProductModal';

const Reservation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const history = useHistory();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const showId = getQueryString('showId');
  const calendarRef = useRef(null);
  const canBook = useSelector((state) => state.associates.roles).includes('associate');
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [today, setToday] = useState(null);
  const [compareToday, setCompareToday] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [selectedPax, setSelectedPax] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [intervals, setIntervals] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: showId,
          lang: language,
          reservationType: 'RESTAURANT',
        },
      });

      setShow(result.data);
      const todayDate = new Date(result.data.calendar.minDate);
      const yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const firstDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      setToday(todayDate);
      setCompareToday(todayDate);
      setMinDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0 + 1));
      setDisabledDates(generateDisabledDates(firstDate, yesterdayDate, locale));
      setSelectedDate(todayDate);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getSchedule = async (date, reservationType) => {
    const formatedDate = format(date, 'yyyy-MM-dd');

    setLoadingSchedule(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/GetSchedule?showId=${showId}&date=${formatedDate}&reservationType=${reservationType}`,
      );

      if (result.data.length === 0) {
        setIntervals([]);
      }

      const formattedTimes = result.data?.map((time) => {
        const time24 = new Date(
          format(date, 'yyyy-MM-dd') + 'T' + convertTime12to24(time.timeStart),
        );

        if (time.timeMinutesStart >= 0 && time.timeMinutesStart <= 360)
          time24.setDate(time24.getDate() + 1);

        return { time24, soldOut: time.isSoldOut };
      });
      formattedTimes.sort();

      const allIntervals = formattedTimes.map(({ time24, soldOut }) => ({
        time: time24,
        soldOut,
      }));

      setIntervals(allIntervals);
      setLoadingSchedule(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShow();
  }, []);

  useEffect(() => {
    if (today !== null) getSchedule(today, 'RESTAURANT');
  }, [today]);

  const enableSelect = () => {
    setSelectDisabled(false);
  };

  const handleChangeDate = async (day) => {
    setSelectedPerformance(null);
    if (day !== undefined) {
      setSelectedDate(day);
      getSchedule(day, 'RESTAURANT');
    }
    setCalendarVisible(!calendarVisible);
  };

  const onSelectPerformance = (performance) => {
    setSelectedPerformance(performance);
  };

  const handleContinue = () => {
    const reservationInfo = {
      showId: showId,
      showName: show.name,
      products: null,
      performance: selectedPerformance,
      date: format(selectedDate, 'yyyy-MM-dd'),
      paxs: selectedPax,
    };

    dispatch(setReservation(reservationInfo));
    if (canBook) {
      history.push({
        pathname: '/reservation-check',
        search: `?show=${show.name}`,
      });
    } else {
      setErrorMessage(t('cantBook'));
    }
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <ProductModal
        open={calendarVisible}
        fullScreen
        animationClass='fade-up-horizontal'
        hasBackground
        showCloseButton={false}
      >
        <Header onClick={() => setCalendarVisible(false)} showBack />
        <Calendar
          disabledDates={disabledDates}
          minDate={minDate}
          onSelectDate={handleChangeDate}
          selectedDate={selectedDate}
        />
      </ProductModal>

      <Header title={<div className='text-capitalize px-3'>{t('book') + ' ' + show.name}</div>} />
      <ContentApp menu={true}>
        {!loading && compareToday !== null && selectedDate !== null ? (
          <>
            <div className='mt-4 '>
              <ReservationSelector
                selectedPax={selectedPax}
                setSelectedPax={setSelectedPax}
                selectedDate={selectedDate}
                selectDisabled={selectDisabled}
                enableSelect={enableSelect}
                handleChangeDate={handleChangeDate}
                loading={loadingSchedule}
                setCalendarVisible={setCalendarVisible}
                calendarVisible={calendarVisible}
                setSelectDisabled={setSelectDisabled}
                calendarRef={calendarRef}
                initialSlide={0}
              />
            </div>
            <div className='mt-2'>
              {!loadingSchedule ? (
                <>
                  {intervals?.length > 0 && !loadingSchedule ? (
                    <div className='fade-up'>
                      <div className='mt-3 mb-1 text-secondary bold'>{t('selectTime')}</div>
                      <div className='mt-3'>
                        <ProductPerformanceSelector
                          performances={intervals}
                          onSelect={onSelectPerformance}
                          selectedPerformance={selectedPerformance}
                          language={language}
                          selectedDate={selectedDate}
                          loadingSchedule={loadingSchedule}
                          today={compareToday}
                        />
                      </div>
                    </div>
                  ) : !loadingSchedule ? (
                    <EmptyActivity icon={'notification'} title={'No hay horarios disponibles'} />
                  ) : (
                    skeletonSchedule()
                  )}
                </>
              ) : (
                <>{skeletonEmptyPerformances()}</>
              )}
            </div>
            <div
              className='position-fixed w-100'
              style={{
                bottom: 0,
                left: 0,
                backgroundColor: '#000',
                paddingBottom: 'env(safe-area-inset-bottom)',
              }}
            >
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-7 col-lg-6'>
                    {errorMessage && <ErrorBox title={errorMessage} />}
                    <Button
                      text={t('continue')}
                      size='large'
                      className={`mt-2 mb-3 ${styles.btn}`}
                      onClick={handleContinue}
                      icon={true}
                      disabled={!selectedPax || !selectedPerformance}
                      typeIcon='arrow'
                      iconColor='#000'
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </ContentApp>
    </>
  );
};

export default Reservation;
