import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileImage } from '../../actions/associatesActions';
import UserIcon from '../icons/userIcon';
import ConfigIcon from '../icons/ConfigIcon';
import SearchIcon from '../icons/SearchIcon';
import Modal from '../Common/Modal/Modal';
import Menu from '../../screens/User/Menu/Menu';
import Button from '../Common/Button/Button';
import styles from './AccountHeader.module.css';
import ChevronIcon from '../icons/chevronIcon';

const AccountHeader = ({ title, icon, pathName, chevron = false }) => {
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModal, setIsModal] = useState('');
  const user = useSelector((state) => state.associates.user);
  const jwt = useSelector((state) => state.associates.jwt);
  const dispatch = useDispatch();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!user.profileImage) {
      dispatch(getProfileImage(user.profilePic, jwt));
    }
  }, []);

  const closeModal = () => {
    setIsModal('');
  };

  const getIcon = (icon, pathName) => {
    switch (icon) {
      case 'config':
        return (
          <div
            className='col-auto my-auto text-center pl-0'
            style={{ width: '50px' }}
            onClick={() => history.push(pathName)}
          >
            <ConfigIcon size={'1.3rem'} color={'var(--color-lighter-gray)'} />
          </div>
        );

      case 'search':
        return (
          <div className='col-auto my-auto text-right pl-0' style={{ width: '50px' }}>
            <SearchIcon size={'20px'} color={'#fff'} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`${styles.headerUser} ${styles.container} header app position-fixed w-100 `}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-7 col-lg-6'>
              <div className={`row ${styles.headerContent}`}>
                <div className='col-auto pr-0 my-auto' onClick={toggleMenu}>
                  <div className={styles.profileContainer}>
                    {user?.profilePic ? (
                      <img src={user.profileImage} alt={user.displayName} />
                    ) : chevron ? (
                      <ChevronIcon />
                    ) : (
                      <UserIcon size={20} color='#fff' />
                    )}
                  </div>
                </div>

                <div className='col logo text-center text-lighter-gray my-auto bold'>
                  {title ? (
                    title
                  ) : (
                    <div className='image-container-header'>
                      <img
                        src='https://gruporosanegra.com.mx/images/logos/Negativo_GRN.png'
                        alt='rosanegra'
                        className='image-menu'
                      />
                    </div>
                  )}
                </div>

                {getIcon(icon, pathName)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isMenuOpen}
        onClose={toggleMenu}
        animationClass='slide-left-right'
        fullScreen={true}
        hasBackground={true}
      >
        <Menu history={history} />
      </Modal>
      <Modal
        onClose={closeModal}
        animationClass={'fade'}
        open={isModal === 'modal-personal-validation'}
        idModal='modal-personal-validation'
      >
        <div className='text-center'>
          <div className=' bold'>Debes validarte primero</div>
          <div className='text-secondary mt-1'>
            Antes de que te postules, necesitamos validar tus datos personales.
          </div>
          <Button
            size='medium'
            text={'Validar mis datos'}
            onClick={() => history.push('/kyc-start')}
            className='mt-3'
          />
        </div>
      </Modal>
    </>
  );
};
export default AccountHeader;
