import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import parseJwt from '../helpers/parseJwt';
import gigamont from '../api/gigamont';
import { setLanguage } from './appActions';
import * as Sentry from '@sentry/react';

const baseUrl = process.env.REACT_APP_SALES_API_URL;

export const authChecked = () => {
  return { type: 'ASSOCIATES_AUTH_CHECKED' };
};

export const firstLogin = (welletContext) => {
  try {
    welletContext.apis.tickets.get('/associates/firstlogin');
  } catch (error) {
    console.error(error);
  }

  return { type: 'FIRST_LOGIN' };
};

export const exchangeToken = async (dispatch, user, callback = null, history) => {
  const token = await user.getIdToken();
  try {
    const response = await axios.post(`${baseUrl}/auth/exchange`, { token });

    gigamont().defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    await dispatch(associateAuthenticated(response.data.token));

    dispatch(getAssociateSaleSetup(response.data.token));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    //dispatch(logout());
    console.error(error);
  }
};

export const getUserInfo = (jwt) => {
  return axios.get(`${baseUrl}/api/User/info`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const associateAuthenticated = (jwt) => {
  const userDetails = jwt ? parseJwt(jwt) : null;
  const userRoles = userDetails['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

  return {
    type: 'ASSOCIATES_AUTHENTICATED',
    jwt,
    userRoles,
  };
};

export const toggleCommission = () => {
  return { type: 'ASSOCIATES_TOGGLE_COMMISSION' };
};

export const logout = (callback = null) => {
  return (dispatch) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch({ type: 'ASSOCIATES_LOGOUT' });
        Sentry.setUser(null);
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch((error) => {
        // An error happened.
      });
  };
};

export const getAssociateSaleSetup = (jwt) => {
  return async (dispatch) => {
    try {
      const setup = await gigamont().get('/associates/salesetup', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      Sentry.setUser({
        id: setup.data.userInfo.sid,
        email: setup.data.userInfo.email,
      });

      await dispatch({
        type: 'ASSOCIATES_INFO',
        payload: setup.data.userInfo,
      });

      await dispatch({
        type: 'FETCH_CURRENCIES',
        payload: setup.data.currencies,
      });

      await dispatch(setLanguage(setup.data.userInfo.language));

      if (setup.data.userInfo.profilePic !== '') {
        dispatch(getProfileImage(setup.data.userInfo.profilePic, jwt));
      }
    } catch (error) {
      if (error.response.status === 400) await dispatch(logout());

      console.error(error);
    }
  };
};

export const getProfileImage = (imageUrl, jwt) => {
  return async (dispatch) => {
    try {
      const response = await gigamont().get(imageUrl, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(response.data);
      fileReaderInstance.onload = () => {
        dispatch({
          type: 'ASSOCIATES_PROFILE_IMAGE',
          payload: fileReaderInstance.result,
        });
      };
    } catch (error) {
      console.error(error);
    }
  };
};

export const withdraw = (welletContext, amount, currency, id) => async (dispatch) => {
  try {
    const result = await welletContext.apis.tickets.post('/Withdrawal', {
      amount: amount,
      currency: currency,
      userWithDrawalId: id,
    });
    if (result && result.status === 200) {
      dispatch({
        type: 'WITHDRAW_SUCCESS',
        payload: result.data,
      });
    } else {
      const errorType = result?.data ?? 'ERROR_PROCESSING_DISBURSEMENT';
      dispatch({
        type: 'WITHDRAW_ERROR',
        payload: errorType,
      });
    }
  } catch (error) {
    const errorType = error?.response?.data ?? 'ERROR_PROCESSING_DISBURSEMENT';
    dispatch({
      type: 'WITHDRAW_ERROR',
      payload: errorType,
    });
  }
};

export const sendPdfQR = async (welletContext, organizationId) => {
  return await welletContext.apis.tickets.get(
    `/associates/sendpdfqr?organizationId=${organizationId}`,
  );
};

export const getAssociateStatusAccount = (welletContext, jwt) => {
  return async (dispatch) => {
    try {
      const setup = await welletContext.apis.tickets.get('associates/affiliation/status', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      await dispatch({
        type: 'STATUS_ACCOUNT',
        payload: setup.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getPaymentMethod = async (welletContext) => {
  try {
    const setup = await welletContext.apis.tickets.get('/withdrawal/GetOneUserWithdrawal');
    return setup;
  } catch (error) {
    console.error(error);
  }
};

export const setRegisterCompleted = (value) => {
  return {
    type: 'SIGNUP_SUCCESS',
    payload: value, // This should be true or false
  };
};

export const markNotificationAsRead = (notificationId) => ({
  type: 'MARK_NOTIFICATION_AS_READ',
  payload: notificationId,
});
