import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';

import styles from './LoginScreen.module.css';
import { useSelector } from 'react-redux';

const Login = ({ history }) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);

  const onSignInHandle = () => {
    history.push({
      pathname: '/login-user',
    });
  };

  const onSignUpHandle = () => {
    history.push({
      pathname: '/sign-up-terms',
    });
  };

  return (
    <div>
      <div
        className={styles.container}
        style={{ backgroundImage: `url(${app.backGroundImageUrl})` }}
      >
        <div>
          <img src={app.logoType} alt='logo' className={styles.logoType} />
        </div>
      </div>
      <div className=' mb-5 px-3' style={{ position: 'absolute', bottom: '0', width: '100%' }}>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 col-lg-5 p-md-0'>
            <div className='mt-3'>
              <Button
                size={'large'}
                onClick={onSignInHandle}
                text={t('login.login')}
                color='#000'
                backgroundColor='var(--color-primary)'
              />
              <div className='mt-2'>
                <Button
                  size='large'
                  className='position-relative'
                  onClick={onSignUpHandle}
                  text={t('login.newAccount')}
                  color='#000'
                  backgroundColor='#fff'
                  icon={true}
                  typeIcon='arrow'
                  iconColor='#000'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
