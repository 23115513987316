import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/WelletContext';
import { errorText } from '../../../helpers/login';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import InputFormNew from '../../../components/Common/InputFormNew/InputFormNew';
import Button from '../../../components/Common/Button/Button';
import useForm from '../../../helpers/useForm';
import { Spinner } from '../../../components/Common/Spinner/Spinner';

const LoginUserScreen = () => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [errorUser, setErrorUser] = useState(undefined);
  const [verifyingUser, setVerifyingUser] = useState(false);

  function validate(values) {
    let errors = {};

    if (!values.email) {
      errors.email = t('emailError.EMPTY_EMAIL');
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t('emailError.INVALID_EMAIL');
    }

    return errors;
  }
  const onContinueHandle = (email) => {
    history.push({
      pathname: '/login-password',
      search: '?email=' + email,
    });
  };
  const tenantId = useSelector((state) => state.app.tenantId);

  const sendForm = async () => {
    setVerifyingUser(true);
    setErrorUser(undefined);
    try {
      const result = await welletContext.apis.tickets.post(`users/Available`, {
        email: values.email.trim(),
        tenantId: tenantId,
      });

      if (!result.data.isAvailable) {
        errorText(result.data.availableMessage, setErrorUser, t);
      } else onContinueHandle(values.email.trim());
    } catch (error) {
      console.error(error);
    }
    setVerifyingUser(false);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  const history = useHistory();

  const onChangeEmail = (e) => {
    handleChange(e);
    setErrorUser(null);
  };

  const inputEmail = () => (
    <InputFormNew
      name={'email'}
      type={'email'}
      value={values.email}
      placeholder='E-mail'
      error={errors.email || errorUser}
      onChange={onChangeEmail}
      forceLowecase={true}
    />
  );

  return (
    <>
      <Header title={''} pathName={'/login'} hasBackground={true} />
      <ContentApp background={true}>
        <div className=''>
          <form onSubmit={handleSubmit}>
            <div className='font-medium bold' style={{ color: '#fff' }}>
              {t('enterEmail')}
            </div>
            <div className='mt-5 '>{inputEmail()}</div>
            <div className='mt-5'>
              <Button
                size={'large'}
                text={
                  verifyingUser ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('continue')
                  )
                }
                className={`${verifyingUser ? 'loading' : ''} btn-primary`}
                hasBorder={true}
                icon={verifyingUser ? false : true}
                iconColor='#000'
                typeIcon='arrow'
              />
            </div>
          </form>
          <div
            className='text-center mt-5 text-primary'
            onClick={() => history.push('/sign-up-terms')}
          >
            {t('login.signUp')}
          </div>
        </div>
      </ContentApp>
    </>
  );
};

export default LoginUserScreen;
