import React, { useEffect, useState } from 'react';
import './Toast.css';
import ErrorIcon from '../../icons/ErrorIcon';
import { SuccessIcon } from '../../icons/successIcon';

export const ToastType = {
  Success: 'SUCCESS',
  Error: 'ERROR',
};

export function Toast({
  message,
  type,
  delay = 1,
  zIndex = 1,
  onCallback,
  top = true,
  initDelay = 300,
}) {
  const [toastStatus, setToastStatus] = useState('');

  useEffect(() => {
    const timerShow = setTimeout(() => {
      setToastStatus('toast-show');
    }, initDelay);

    const timerHide = setTimeout(() => {
      setToastStatus('');
      if (onCallback) onCallback();
    }, delay * 1000 + initDelay);

    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerHide);
    };
  }, [delay, initDelay, onCallback]);

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon color='#fff' size='20px' />;
      case 'success':
        return <SuccessIcon color='#fff' size='20px' />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`wellet-net-toast-container ${top ? 'top-center' : 'bottom-center'} ${toastStatus}`}
      style={{ zIndex }}
    >
      <div className={`wellet-net-toasts font-small toast-${type.toLowerCase()} ${toastStatus}`}>
        <div className='wellet-net-toasts-body'>
          <div className='wellet-toasts-icon'>{getIcon()}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
}
