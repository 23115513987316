import React, { useEffect } from 'react';
import Header from '../Header/Header';
import './ProductModal.css';

const ProductModal = ({
  open = false,
  onClose,
  animationClass,
  fullScreen = false,
  children,
  showCloseButton = true,
  idModal,
  hasBackground = false,
  backIcon = false,
  customStyle,
  title,
}) => {
  let fullscreenClass = fullScreen ? 'modal-fullscreen' : '';
  let modalClass = open ? 'modal show d-block' : 'modal';

  const isModalFull = fullScreen ? {} : { position: 'absolute', top: 0, right: 0 };
  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);
  const handleModalClose = () => {
    onClose();
  };

  const modalContentStyle = fullScreen
    ? {}
    : customStyle
    ? customStyle
    : { maxHeight: '80vh', overflowY: 'auto' };

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);

  return (
    <div
      className={`${modalClass} ${animationClass} ${fullscreenClass}`}
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
      id={idModal}
      style={{ height: '100%', overflowY: 'hidden' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div
          className={`modal-content p-0 product-modal ${hasBackground && 'background-modal'}`}
          style={modalContentStyle}
        >
          {showCloseButton && backIcon && <Header title={title} onClick={handleModalClose} />}
          {showCloseButton && !backIcon && (
            <div style={isModalFull}>
              <button
                type='button'
                onClick={handleModalClose}
                className='close font-large text-secondary'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
