import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { WelletContext } from '../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import SuccessScreen from '../../Common/SuccessScreen';
import Button from '../../../components/Common/Button/Button';
import { getPaymentMethod } from '../../../actions/associatesActions';


const LinkStripeConnect = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  var dispatch = useDispatch();
  const [onboardingEmail, setOnboardingEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null); 

  const sendOnboardingLink = async () => {
    try {
      setLoading(true)
      const response = await welletContext.apis.tickets.post('/withdrawal/stripe/OnboardingEmail');
      setLoading(false)
      setOnboardingEmail(response.data);
      return response.data;
    }
    catch (error) {
      console.error(error)
    }
  }

  const refreshWithdrawalMethod = async () => {
    try {
      const withdrawalMethodResult = await getPaymentMethod(welletContext);
      dispatch({
        type: 'USER_WITHDRAWAL',
        payload: withdrawalMethodResult.data,
      });
      setWithdrawalMethod(withdrawalMethodResult.data);
    }
    catch (error) {
      setWithdrawalMethod('none'); // do not do anything if the user does not have a withdrawal method
    }
  }

  useEffect(() => {
    refreshWithdrawalMethod();
  },[])

  const renderBody = () => {

    if(withdrawalMethod == null) {
      return null;
    }

    if(loading) {
      return (
        <div>
          Enviando email...
        </div>
      )
    }

    return (
      <>
        <div>
          Te enviaremos un email con instrucciones para generar tu cuenta de Stripe.
        </div>
        <Button
                text="Enviar email"
                size='large'
                icon
                typeIcon='arrow'
                color='#000'
                className='btn-primary'
                iconColor='#000'
                onClick={() => sendOnboardingLink()}
                disabled={false}
              />
      </>
    )

  }

  if(onboardingEmail) {
    return (
      <SuccessScreen
        title={`${t('done')}!`}
        subtitle={`Se ha enviado un email a ${onboardingEmail} con un enlace para completar el proceso de vinculación de tu cuenta Stripe. Una vez finalizado el proceso, haz click en Continuar.`}
        onClick={async () => {
          await refreshWithdrawalMethod();
          history.push('/');
        }}
      />
    );
  }

  if(withdrawalMethod !== null && withdrawalMethod !== 'none') {
    return (
      <SuccessScreen
        title='Se ha vinculado tu cuenta de Stripe exitosamente'
        subtitle={`Hemos vinculado tu cuenta de Stripe exitosamente.`}
        path='/'
      />
    );
  }

  
  return (
    <>
      <Header title='Stripe' />
      <ContentApp>
        {renderBody()}
      </ContentApp>
    </>
  );
};

export default LinkStripeConnect;
