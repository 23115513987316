import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import CampaignCard from '../../../../components/Common/CampaignCard/CampaignCard';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../../helpers/dates';
import ContentApp from '../../../../components/ContentApp';
import { WelletContext } from '../../../../context/WelletContext';
import {
  fetchAllSocialNetworks,
  fetchCampaigns,
} from '../../../../services/campaigns/campaignsService';
import styles from './MyCampaigns.module.css';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Common/Button/Button';
import FixedButton from '../../../../components/Common/FixedButton/FixedButton';
import NoCampaignsIcon from '../../../../components/icons/NoCampaignIcon';
import { CircleSpinner } from 'react-spinners-kit';
import AccountFilterIcon from '../../../../components/icons/AccountFilterIcon';
import ProductModal from '../../../../components/Common/ProductModal/ProductModal';
import Select from 'react-select';
import { fetchShow } from '../../../../actions/appActions';

const MyCampaigns = ({ history }) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const welletContext = useContext(WelletContext);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [cities, setCities] = useState(null);
  const [restaurants, setRestaurants] = useState(null);
  const [socialNetworks, setSocialNetworks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const body = {
    CitiesId: [],
    ShowId: '',
    CampaignType: '',
    NetworkCampaignId: '',
    CampaignState: 'ACTIVE',
    OrderByField: 'DATE',
    OrderDirection: 'ASC',
  };

  const getCampaigns = async () => {
    try {
      const response = await fetchCampaigns(welletContext, body);

      setCampaigns(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const getCities = async () => {
    try {
      const response = await welletContext.apis.tickets.get('/shows/cities');
      setCities(response.data);
    } catch (err) {
      console.error(err);
      setError(t('callApiError'));
    }
  };

  const getRestaurants = async (selectedCity, page = 1, pageSize = 100) => {
    try {
      const result = await fetchShow('es', selectedCity, page, pageSize, welletContext);
      setRestaurants(
        result.shows.map((restaurant) => ({
          value: restaurant.id,
          label: restaurant.name,
        })),
      );
    } catch (error) {
      console.error(error);
      setError(t('callApiError'));
    }
  };

  const getSocialNetwork = () => {
    const socialNetworkResponse = fetchAllSocialNetworks(welletContext, 'es');
    setSocialNetworks(socialNetworkResponse.data);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedFilters({
      ...selectedFilters,
      ciudad: selectedOption,
      restaurante: { value: '', label: t('all') },
    });

    if (selectedOption) {
      getRestaurants(selectedOption.value);
    } else {
      setRestaurants(null);
    }
  };

  const handleRestaurantChange = (selectedOption) => {
    setSelectedFilters({ ...selectedFilters, restaurante: selectedOption });
  };

  const handleCampaignTypeChange = (selectedOption) => {
    setSelectedFilters({ ...selectedFilters, reservationType: selectedOption });
  };

  useEffect(() => {
    getCities();
    getSocialNetwork();
  }, []);

  useEffect(() => {
    getRestaurants(selectedFilters?.ciudad?.value);
  }, []);

  const noCampaigns = () => {
    return (
      <div className={styles.noCampaignsContainer}>
        <div className='text-center mt-5'>
          <NoCampaignsIcon size={'100px'} />
          <div className='font-large my-3 bold'>{t('noCampaigns')}</div>
          <div className='text-secondary mt-2 font-weight-500 '>{t('noCampaignsDescription')}</div>
        </div>
        <FixedButton>
          <Button
            size='large'
            text={t('createNewCampaign')}
            onClick={() => history.push('/campaigns')}
          />
        </FixedButton>
      </div>
    );
  };

  const checkboxOptions = [
    {
      label: t('options.checkingAvailability'),
      checked: true,
      value: 'PENDING',
    },
    {
      label: t('options.pendingWarranty'),
      checked: true,
      value: 'WAITING_DEPOSIT',
    },
  ];

  const orderOptions = [
    { label: t('orderTypes.RESERVATION_DATE_ASC'), value: 'RESERVATION_DATE_ASC' },
    { label: t('orderTypes.RESERVATION_DATE_DESC'), value: 'RESERVATION_DATE_DESC' },
    { label: t('orderTypes.CREATED_DATE_ASC'), value: 'CREATED_DATE_ASC' },
    { label: t('orderTypes.CREATED_DATE_DESC'), value: 'CREATED_DATE_DESC' },
  ];

  const campaignTypeOptions = [
    {
      id: 1,
      name: 'Status',
    },
    {
      id: 2,
      name: 'History',
    },
    {
      id: 3,
      name: 'Publication',
    },
    {
      id: 4,
      name: 'Reel',
    },
  ];

  return (
    <>
      {/* <ProductModal
        open={showModal}
        fullScreen
        hasBackground
        showCloseButton={false}
        animationClass='slide-left-right'
      >
        <div style={{ backgroundColor: '#000' }}>
          <Header title={t('filters')} onClick={() => setShowModal(false)} />
          <div className='text-white container'>
            <div className='my-3 bold'>{t('city')}</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              options={[
                { value: '', label: t('all') },
                ...(cities && Array.isArray(cities)
                  ? cities.map((city) => ({
                      value: city.id,
                      label: city.name,
                    }))
                  : []),
              ]}
              onChange={handleCityChange}
              value={selectedFilters?.ciudad || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-3 bold'>{t('restaurant')}</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              options={[
                { value: '', label: t('all') },
                ...(restaurants && Array.isArray(restaurants) ? restaurants : []),
              ]}
              onChange={handleRestaurantChange}
              value={selectedFilters?.restaurante || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-3 bold'>Tipo de campaña</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              // options={[{ value: '', label: t('all') }, ...campaignType(t)]}
              options={campaignTypeOptions}
              onChange={handleCampaignTypeChange}
              value={selectedFilters?.reservationType || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-3 bold'>Red social/canal</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              options={[{ value: '', label: t('all') }, ...networkType(t)]}
              onChange={handleNetworkChange}
              value={selectedFilters?.reservationType || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-4 bold'>{t('reservationRequests')}</div>
            {checkboxOptions.map((checkbox, index) => (
              <div className='row mt-3' key={index}>
                <label className='filter-checkbox-container ml-3'>
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px' }}
                    checked={selectedCheckboxes[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <span className='filter-checkmark' style={{ marginLeft: '' }}></span>
                </label>
                <div className='col ml-3' onClick={() => handleCheckboxChange(index)}>
                  {checkbox.label}
                </div>
              </div>
            ))}

            <div className='bold mt-5'>{t('orderBy')}</div>
            <Select
              className='filter-select hide-indicator-separator mt-3'
              classNamePrefix='filter-select'
              options={orderOptions}
              placeholder={t(`orderTypes.${body.sortBy}`)}
              onChange={handleOrderSelection}
              isSearchable={false}
            />
            <div className='d-flex mt-5 justify-content-between align-items-center mb-2 all-reservations-buttons'>
              <u className='text-secondary font-small pl-0' onClick={handleResetFilters}>
                {t('resetAllFilters')}
              </u>
              <Button onClick={handleOkButtonClick} text='OK' size='small' />
            </div>
          </div>
        </div>
      </ProductModal> */}
      <Header
        title={t('myCampaigns')}
        pathName={'/campaigns'}
        rightIcon={<AccountFilterIcon size={20} />}
      />
      <ContentApp>
        {campaigns.length === 0 && !loading ? (
          noCampaigns()
        ) : campaigns.length > 0 && !loading ? (
          <>
            {campaigns.map((c, index) => (
              <div className={index === 0 ? 'mt-1' : 'mt-2'} key={index}>
                <CampaignCard
                  campaign={c}
                  locale={locale}
                  path={`/my-campaigns/campaign-detail?id=${c.id}`}
                  history={history}
                  isSocialNetwork={false}
                />
              </div>
            ))}
          </>
        ) : (
          loading && (
            <div className='loaderDiv'>
              <CircleSpinner
                className='spinner-app mx-auto'
                style={{ margin: 'auto' }}
                size={40}
                color='var(--color-font-primary)'
              />
            </div>
          )
        )}
      </ContentApp>
    </>
  );
};

export default MyCampaigns;
