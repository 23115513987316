import React, { useEffect, useRef, useState } from 'react';
import styles from './CampaignMediaCard.module.css';

const CampaignMediaCard = ({ campaign, index, history }) => {
  const [open, setOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [startY, setStartY] = useState(0);
  const videoFeedRef = useRef(null);
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

  const handleVideoModal = (e) => {
    e.stopPropagation();
    if (isScrolling) {
      return;
    }
    history.push({
      pathname: '/share-campaign',
      state: { campaign },
    });

    setOpen(!open);
  };

  useEffect(() => {
    if (videoFeedRef.current) {
      const handleLoadedMetadata = () => {
        setVideoDimensions({
          width: videoFeedRef.current.videoWidth,
          height: videoFeedRef.current.videoHeight,
        });
      };

      videoFeedRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
  }, [videoFeedRef]);

  useEffect(() => {
    const handlePlayPause = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.9,
    });

    if (videoFeedRef.current) {
      observer.observe(videoFeedRef.current);
    }

    return () => {
      if (videoFeedRef.current) {
        observer.unobserve(videoFeedRef.current);
      }
    };
  }, []);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const currentY = e.touches[0].clientY;
    const diff = Math.abs(currentY - startY);
    if (diff > 10) {
      setIsScrolling(true);
    }
  };

  const handleTouchEnd = () => {
    setIsScrolling(false);
  };

  return (
    <div
      className={`mt-3 media-item`}
      id={`media-item-${index}`}
      key={index}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className='d-flex align-items-center'>
        <div className={`col-auto pl-0 ${styles.containerImg}`}>
          <img src={campaign.showLogo} className={styles.restaurantImg} alt='logo' />
        </div>
        <div className='col pl-0 font-1rem'>
          <div className='bold'>{campaign.showName}</div>
          <div className='text-secondary'>{campaign.cityName}</div>
        </div>
      </div>
      {campaign?.media[0]?.mediaUrl?.includes('mp4') ? (
        <div onClick={(e) => handleVideoModal(e)}>
          <video
            src={campaign?.media[0]?.mediaUrl + '#t=0.1'}
            loop
            muted
            autoPlay
            className={styles.feedVideo}
            style={{
              objectFit: videoDimensions.width > videoDimensions.height ? '' : 'cover',
            }}
            webkit-playsinline='true'
            x5-playsinline='true'
            playsInline
            ref={videoFeedRef}
          />
        </div>
      ) : (
        <div onClick={(e) => handleVideoModal(e)}>
          <img src={campaign?.media[0]?.mediaUrl} className={styles.feedImg} alt='logo' />
        </div>
      )}

      <div className='mt-2'>{campaign.campaignName}</div>
    </div>
  );
};

export default CampaignMediaCard;
