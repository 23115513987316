import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { getDateFromOffset } from '../../../helpers/dates';
import { WelletContext } from '../../../context/WelletContext';
import { skeletonLoading } from '../../../components/Skeletons/SkeletonAccount';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import Balance from '../../../components/Balance/Balance';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import Button from '../../../components/Common/Button/Button';
import WarningBox from '../../../components/Common/WarningBox/WarningBox';
import ActivityReservationItem from '../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';
import useRealtimeReservations from '../../../hooks/useRealtimeReservations';
import { CircleSpinner } from 'react-spinners-kit';
import AccountFilterIcon from '../../../components/icons/AccountFilterIcon';

const Account = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const jwt = useSelector((state) => state.associates.jwt);
  const user = useSelector((state) => state.associates.user);
  const userWithdrawalMethod = user?.userWithdrawalMethod;
  const today = getDateFromOffset(user.companyUtcMinutesOffset);
  const todayFilter = format(today, 'yyyy-MM-dd');
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [filterDate, setFilterDate] = useState(
    localStorage.getItem('filterDate') ?? 'RESERVATION_DATE',
  );

  const [myEarningsToday, setMyEarningsToday] = useState(null);
  const [monthEarning, setMonthEarning] = useState(0);
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);
  const [selectedOption, setSelectedOption] = useState(filterDate);
  const app = useSelector((state) => state.app);

  const wireMethod =
    user.withdrawalMethod === 'WIRE_INTERNATIONAL' || user.withdrawalMethod === 'WIRE_MEXICO';
  const cashOrCertificate =
    user.withdrawalMethod === 'CASH' || user.withdrawalMethod === 'CERTIFICATE';

  const getMyEarningsToday = async () => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${todayFilter}&to=${todayFilter}`,
      );
      setMyEarningsToday(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const getEarnings = async (fromDate, toDate) => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${fromDate}&to=${toDate}`,
      );
      setMonthEarning(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const handleValidReservationUpdate = useCallback(() => {
    getMyEarningsToday();

    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [getMyEarningsToday, getEarnings]);

  const { reservations, loading, hasMore, loadingMore, fetchMoreReservations } =
    useRealtimeReservations(today, filterDate, handleValidReservationUpdate);

  useEffect(() => {
    getMyEarningsToday();
    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [jwt]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedFilterDate = localStorage.getItem('filterDate');
    if (storedFilterDate) {
      setFilterDate(storedFilterDate);
    }
  }, []);

  const isEmptyActivity = reservations?.length === 0 || undefined;

  useEffect(() => {
    if (!localStorage.getItem('filterDate')) localStorage.setItem('filterDate', 'RESERVATION_DATE');
  }, []);

  const handleSelectChange = (selectedOption) => {
    localStorage.setItem('filterDate', selectedOption);
    setFilterDate(selectedOption);
    setSelectedOption(selectedOption);
  };

  const renderWithdrawalMethodWarningBox = () => {
    if (kycStatus !== 'APPROVED' && !userWithdrawalMethod) {
      return null;
    }

    if (userWithdrawalMethod) {
      return null;
    }

    if (cashOrCertificate) {
      return null;
    }

    let title = '';
    let subtitle = '';
    let nextUrl = '';

    if (user.withdrawalMethod === 'STRIPE') {
      title = t('noAccountAssociated.STRIPE.title');
      subtitle = t('noAccountAssociated.STRIPE.subtitle');
      nextUrl = '/stripeaccount';
    } else if (user.withdrawalMethod === 'MERCADOPAGO') {
      title = t('noAccountAssociated.mercadoPago.title');
      subtitle = t('noAccountAssociated.mercadoPago.subtitle');
      nextUrl = '/mercadopagoaccount';
    } else if (wireMethod) {
      title = t('noAccountAssociated.WIRE.title');
      subtitle = t('noAccountAssociated.WIRE.subtitle');
      if (user.withdrawalMethod === 'WIRE_INTERNATIONAL') {
        nextUrl = '/international-bank';
      } else if (user.withdrawalMethod === 'WIRE_MEXICO') {
        nextUrl = '/national-bank';
      }
    }

    return (
      <WarningBox
        title={title}
        subtitle={
          <div>
            {subtitle}
            <span style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}>
              {t('here')}
            </span>
            .
          </div>
        }
        onClick={() => history.push(nextUrl)}
      />
    );
  };

  return (
    <div>
      <AccountHeader title={app.name} icon={'config'} pathName='/configuration' />
      <ContentApp menu header>
        {kycStatus !== 'APPROVED' && (
          <WarningBox
            title={t('identityNotValidated.title')}
            subtitle={t('identityNotValidated.subtitle')}
          />
        )}
        {renderWithdrawalMethodWarningBox()}
        <div className='mt-2'>
          <Balance
            title={t('earnings')}
            todayBalance={myEarningsToday?.amount}
            last7DaysBalance={monthEarning?.amount}
            showText={true}
            fontSize='1.8rem'
            showAllBalances={true}
            loading={loadingBalance}
            mpVerified={true}
            showArrow={true}
          />
        </div>

        <div className='d-flex'>
          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <Button
              size='large'
              className='col mr-2 btn-primary mt-3 btn-account'
              text={t('myLink')}
              iconColor='#000'
              icon={true}
              typeIcon='link'
              onClick={() => history.push('/campaigns-feed')}
            />
          )}
          <Button
            size='large'
            className='col btn-primary mt-3 btn-account'
            text={t('bookEarnProfits')}
            icon={true}
            typeIcon='arrow'
            iconColor='#000'
            onClick={() => history.push('/restaurant-list')}
          />
        </div>

        <div className='bold font-normal text-lighter-gray mt-3'>{t('myReservations')}</div>
        <div className='row mt-3 mb-4'>
          <div className='col-auto pr-0'>
            <div className='account-button' onClick={() => history.push('/all-reservations')}>
              <AccountFilterIcon color='#7E7E7E' size='16px' styles={{ marginTop: '-2px' }} />
            </div>
          </div>
          <div className='col-auto px-1'>
            <div
              className={`account-button ${
                selectedOption === 'RESERVATION_DATE' ? 'selected' : ''
              }`}
              onClick={() => handleSelectChange('RESERVATION_DATE')}
            >
              {t('forToday')}
            </div>
          </div>
          <div className='col-auto col-md p-0'>
            <div
              className={`account-button ${selectedOption === 'CREATED_DATE' ? 'selected' : ''}`}
              onClick={() => handleSelectChange('CREATED_DATE')}
            >
              {t('mostRecent')}
            </div>
          </div>
          <div className='col pl-1'>
            <div
              className={`account-button ${
                selectedOption === 'RECENT_COMMISSIONS' ? 'selected' : ''
              }`}
              onClick={() => handleSelectChange('RECENT_COMMISSIONS')}
            >
              {t('lastCommissions')}
            </div>
          </div>
        </div>

        {loading ? (
          skeletonLoading()
        ) : (
          <>
            {isEmptyActivity ? (
              <EmptyActivity subtitle={<div className='bold'>{t('noBookings')}</div>} />
            ) : (
              <>
                {reservations?.map((r, index) => (
                  <div key={index}>
                    <ActivityReservationItem
                      activity={r}
                      date={r?.reservationLocalTime}
                      rightText={r.amount}
                      index={index}
                      lastIndex={reservations.length - 1}
                      handleClick={() => history.push(`/operation-details?id=${r.id}`)}
                    />
                  </div>
                ))}
                {loadingMore ? (
                  <div className='p-3 d-flex justify-content-center'>
                    <CircleSpinner
                      className='spinner-app mx-auto'
                      style={{ margin: 'auto' }}
                      size={40}
                      color='var(--color-font-primary)'
                    />
                  </div>
                ) : (
                  hasMore && (
                    <div
                      className='text-secondary font-small fw-semibold text-center p-2 mt-2'
                      style={{ border: '1px solid #3D3D3D', borderRadius: '5px' }}
                      onClick={fetchMoreReservations}
                    >
                      {t('showMore')}
                    </div>
                  )
                )}
              </>
            )}
          </>
        )}

        <CustomerNav history={history} />
      </ContentApp>
    </div>
  );
};

export default Account;
