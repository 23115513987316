import React, { useEffect, useRef, useState } from 'react';
import ProductModal from '../../../../components/Common/ProductModal/ProductModal';
import Header from '../../../../components/Common/Header/Header';
import styles from './ShareCampaign.module.css';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../../components/Common/Toast/Toast';
import step1Android from '../../../../images/step1-android.png';
import step2Android from '../../../../images/step2-android.png';
import step1 from '../../../../images/step1.png';
import step2 from '../../../../images/step2.png';
import step3 from '../../../../images/step3.png';
import step4 from '../../../../images/step4.png';
import ContentApp from '../../../../components/ContentApp';
import InstagramIcon from '../../../../components/icons/InstagramIcon';
import StickerIcon from '../../../../components/icons/StickerIcon';
import LinkInstagramIcon from '../../../../components/icons/LinkInstagramIcon';

const ShareCampaign = ({ history }) => {
  const { t } = useTranslation();
  const campaign = history.location.state.campaign;
  const videoModalRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const steps = [
    !isIOS && {
      img: step1Android,
      title: t('shareCampaigns.clickMore'),
    },
    !isIOS && {
      img: step2Android,
      title: t('shareCampaigns.selectStories'),
    },
    {
      img: step1,
      title: (
        <div>
          {t('shareCampaigns.clickOnThe')} <StickerIcon size={20} /> {t('shareCampaigns.button')}
        </div>
      ),
    },
    {
      img: step2,
      title: (
        <div>
          {t('shareCampaigns.clickOnThe')} <LinkInstagramIcon /> sticker
        </div>
      ),
    },
    {
      img: step3,
      title: t('shareCampaigns.pasteTheLink'),
    },
    {
      img: step4,
      title: t('shareCampaigns.frameYourLink'),
    },
  ].filter(Boolean);

  const handleClickShare = (mediaUrl, type, link) => {
    if (window.webkit) {
      const request = { mediaUrl: mediaUrl, mediaType: type, link: link };
      try {
        window.webkit.messageHandlers.welletShare.postMessage(request);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleVideoClick = () => {
    if (videoModalRef.current) {
      if (isPlaying) {
        videoModalRef.current.pause();
      } else {
        videoModalRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleCloseShare = () => {
    setOpenShareModal(false);
    setActiveStep(1);
  };

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const handleShare = async (mediaUrl, mediaType, link) => {
    try {
      const response = await fetch(mediaUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const fileExtension = mediaUrl.includes('.mp4')
        ? '.mp4'
        : mediaUrl.includes('.webm')
        ? '.webm'
        : '.png';
      const filesArray = [
        new File([blob], `${normalizeFileName(campaign.showName)}${fileExtension}`, {
          type: blob.type,
        }),
      ];
      if (isIOS) {
        handleClickShare(mediaUrl, mediaType, link);
      } else {
        await navigator.share({
          title: campaign.campaignName,
          url: link,
          files: filesArray,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Header title='Reels' />
      <ContentApp classes='p-0'>
        <div className={styles.containerImgModal}>
          {campaign?.media[0]?.mediaUrl?.includes('mp4') ? (
            <video
              autoPlay
              src={campaign?.media[0]?.mediaUrl}
              loop
              className={styles.videoModal}
              onClick={(e) => handleVideoClick(e)}
              ref={videoModalRef}
              webkit-playsinline='true'
              x5-playsinline='true'
              playsInline
            />
          ) : (
            <img src={campaign?.media[0]?.mediaUrl} className={styles.imgModal} alt='logo' />
          )}
        </div>

        <div className='text-center text-white mt-2'>{campaign.showName}</div>
        <div className={`m-3 p-3 ${styles.stepCard}`}>
          <div className='bold'>{t('shareCampaigns.step1')}</div>
          <div className='font-1rem bold text-secondary my-2'>{campaign.link}</div>
          <div className={styles.shareButton} onClick={() => onCopy(campaign.link)}>
            {' '}
            {t('shareCampaigns.copyLink')}
          </div>
          {copied && <Toast top={false} type={'success'} message={t('linkCopied')} delay={3} />}
        </div>
        <div className={`mx-3 p-3 ${styles.stepCard}`}>
          <span className='mb-3 px-3 bold'>{t('shareCampaigns.step2')}</span>
          <button className={styles.shareButton} onClick={() => setOpenShareModal(true)}>
            {t('shareCampaigns.share')}
          </button>
        </div>
      </ContentApp>

      <ProductModal
        animationClass='slide-right-left'
        open={openShareModal}
        hasBackground
        fullScreen
        backIcon
        onClose={() => handleCloseShare()}
      >
        <ContentApp classes='pt-0'>
          <div className='d-flex justify-content-center'>
            <div className={styles.iconButton}>
              <InstagramIcon size={20} color='#000' />
            </div>
          </div>
          <div className={styles.cardShareCampaigns}>
            <div className='mt-2 px-3 pt-3'>{t('shareCampaigns.howToAdd')}</div>
            <div className={styles.stepsButtons}>
              {steps.map((step, idx) => (
                <button
                  key={idx}
                  style={{
                    backgroundColor: activeStep === idx + 1 ? '#000' : '',
                    color: activeStep === idx + 1 ? '#fff' : '#000',
                  }}
                  onClick={() => setActiveStep(idx + 1)}
                >
                  {idx + 1}
                </button>
              ))}
            </div>
            {steps.map((step, idx) => (
              <div key={idx} style={{ display: idx + 1 === activeStep ? 'block' : 'none' }}>
                <div className='my-3'>{step.title}</div>
                <img className={styles.imgStep} src={step.img} alt={`Step ${idx + 1}`} />
              </div>
            ))}
            {(activeStep === 4 && isIOS) || (activeStep === 6 && !isIOS) ? (
              <button
                className={styles.nextStepButton}
                onClick={() =>
                  handleShare(
                    campaign?.media[0]?.mediaUrl,
                    campaign?.media[0]?.mediaType,
                    campaign.link,
                  )
                }
              >
                {t('shareCampaigns.shareInstagram')}
              </button>
            ) : (
              <button
                className={styles.nextStepButton}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                {t('shareCampaigns.nextStep')}
              </button>
            )}
          </div>
        </ContentApp>
      </ProductModal>
    </div>
  );
};

export default ShareCampaign;
