import { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { getQueryString } from '../../../helpers/url';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ReservationTypeCard from '../../../components/ReservationTypeCard/ReservationTypeCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetReservation } from '../../../actions/reservationActions';

const ReservationType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const showId = getQueryString('showId');
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null);

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: showId,
          lang: language,
        },
      });

      setShow(result.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetReservation());
    getShow();
  }, []);

  const handleClick = (reservationType) => {
    history.push({
      pathname:
        reservationType === 'restaurant'
          ? '/reservation-restaurant'
          : '/reservation/' + reservationType,
      search: `?showId=${showId}`,
    });
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize px-3'>{t('book') + ' ' + show.name}</div>} />
      <ContentApp>
        <div>
          <ReservationTypeCard
            title={'Restaurant'}
            image={'/images/reservation.jpg'}
            onHandleClick={() => handleClick('restaurant')}
          />
        </div>
        {show.reservationTypes.map((r) => (
          <div className='mt-3' key={r}>
            <ReservationTypeCard
              title={t(`reservation${r.toLowerCase()}`)}
              image={`/images/reservation-${r.toLowerCase()}.jpg`}
              onHandleClick={() => handleClick(r.toLowerCase())}
            />
          </div>
        ))}
      </ContentApp>
    </>
  );
};

export default ReservationType;
