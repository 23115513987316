import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { useSelector } from 'react-redux';



const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app)
  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white'>
          {t('privacyStatementTerms.title', {
                      organization: app.name ,
                    })}
          
          
          </div>
        <div className='text-secondary mt-3'>
          <p className='text-white bold mb-2'>{t('privacyStatementTerms.subtitle1')}</p>
          <p>{t('privacyStatementTerms.description1')}</p>
          <p className='text-white bold mb-2'>{t('privacyStatementTerms.subtitle2')}</p>
          <p>{t('privacyStatementTerms.description2')}</p>
          <p className='text-white bold mb-2'>{t('privacyStatementTerms.subtitle3')}</p>
          <p>{t('privacyStatementTerms.description3')}</p>
          <p className='text-white bold mb-2'>{t('privacyStatementTerms.subtitle4')}</p>
          <p>{t('privacyStatementTerms.description4')}</p>
          <p className='text-white bold mb-2'>{t('privacyStatementTerms.subtitle5')}</p>
          <p>{t('privacyStatementTerms.description5')}</p>
        </div>
      </ContentApp>
    </>
  );
};

export default PrivacyPolicy;
