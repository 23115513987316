import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Renders route only if user is authenticated. Otherwise it redirects to login

export default function AuthRoute({ path, render }) {
  const authenticated = useSelector((state) => state.associates.authenticated);
  const associates = useSelector((state) => state.associates);
  const allowedRoles = ['associate', 'cashier', 'salesteamlead', 'bosssales'];
  const hasAllowedRole = (roles) => roles.some((role) => allowedRoles.includes(role));
  const roles = associates.roles;

  const renderRoute = (props) => {
    if (authenticated) {
      if (!hasAllowedRole(roles) || associates.user.kycStatus === 'REJECTED') {
        return <Redirect to={{ pathname: '/unauthorized' }} />;
      }

      if (associates.user.kycStatus === 'INVALID' || associates.user.kycStatus === 'PENDING')
        return <Redirect to={{ pathname: '/kyc-start' }} />;
      else if (associates.user.kycStatus === 'REVALIDATION')
        return <Redirect to={{ pathname: '/sign-up-concierge-info' }} />;

      if (associates.user.needPasswordReset && path !== '/change-password') {
        return <Redirect to={{ pathname: '/change-password' }} />;
      } else if (
        !associates.user.firstLogin &&
        path !== '/welcome' &&
        path !== '/onboarding' &&
        !associates.user.needPasswordReset
      ) {
        return <Redirect to={{ pathname: '/welcome' }} />;
      } else {
        return render(props);
      }
    } else {
      return <Redirect to={{ pathname: '/login' }} />;
    }
  };
  return <Route exact path={path} render={renderRoute} />;
}
