import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getLocale, alignTimezone } from '../../../helpers/dates';
import { fetchShow } from '../../../actions/appActions';
import { getCookie, setCookie } from '../../../helpers/cookie';
import { SkeletonCommissions } from '../../Skeletons/SkeletonPoints';
import { useTranslation } from 'react-i18next';
import RestaurantSelector from './RestaurantSelector';
import styles from './statusCommissions.module.css';
import { getCategory } from '../../../helpers/category';
import { WelletContext } from '../../../context/WelletContext';

const StatusCommissions = ({ categoryName, title }) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const qualifyDate = useSelector((state) => state.associates.user.salesCategory.fromDateUTC);
  const locale = getLocale(app.language);
  const newQualifyDate = new Date(qualifyDate);
  const [restaurants, setRestaurants] = useState(null);
  const [loadingRestaurants, setLoadingRestaurants] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [commisions, setCommissions] = useState(null);
  const qualifyDateFormatted = format(alignTimezone(newQualifyDate), "dd 'de' MMMM YYY", {
    locale,
  });
  const welletContext = useContext(WelletContext);
  const restaurantCookie = getCookie('selectedRestaurant');

  const onRestaurantClick = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setCookie('selectedRestaurant', JSON.stringify(restaurant), 1440);
    getCommissions(restaurant.id);
  };

  const getRestaurants = async (selectedCity, page = 1, pageSize = 100) => {
    setLoadingRestaurants(true);
    try {
      const result = await fetchShow(app.language, selectedCity, page, pageSize, welletContext);
      const simplifiedRestaurants = result.shows.map((restaurant) => ({
        id: restaurant.id,
        name: restaurant.name,
      }));

      setRestaurants((prevData) => {
        if (prevData) {
          return [...prevData, ...simplifiedRestaurants];
        } else {
          return simplifiedRestaurants;
        }
      });

      setSelectedRestaurant(
        restaurantCookie !== '' ? JSON.parse(restaurantCookie) : simplifiedRestaurants[0],
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRestaurants(false);
    }
  };

  const getCommissions = async (id = selectedRestaurant?.id) => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(`associates/getAllsalesCategory/${id}`);
      if (response.status === 200) {
        setCommissions(response.data.categoriesList.reverse());
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const selectStyles = {
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'start',
      color: '#000 !important',
      background: 'var(--color-dark-gray) !important',
      textAlign: 'left',
      paddingLeft: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      background: 'var(--color-dark-gray) !important',
      padding: 0,
      border: state.isFocused && 'none',
      boxShadow: state.isFocused && 'none',
      width: 170,
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left',
    }),
  };

  useEffect(() => {
    if (!restaurants) {
      getRestaurants();
    }
  }, []);

  useEffect(() => {
    if (restaurants) {
      getCommissions(selectedRestaurant.id);
    }
  }, [restaurants]);

  return (
    <div className={`card m-0 p-0`}>
      <div className='px-3 pt-3'>
        <div className={' bold'}>{title}</div>
        <div className=' text-secondary font-small'>
          {t('updatedOn')} {qualifyDateFormatted}
        </div>
        <div className={`${styles.line} row mt-2`}></div>
        <div className='row justify-content-between align-items-center mt-2'>
          <div className='col ml-3'>
            <div className='text-secondary font-small bold row'>{t('restaurantVenue')}</div>
            <RestaurantSelector
              restaurants={restaurants}
              selectedRestaurant={selectedRestaurant}
              handleRestaurantChange={onRestaurantClick}
              selectStyles={selectStyles}
              selectClassName='commisions-selector'
            />
          </div>
          <div className='col-auto pl-0 text-secondary font-tiny bold'>{t('commission')} *</div>
        </div>
      </div>
      {!loading && !loadingRestaurants
        ? commisions?.map((c, index) => (
            <div key={index} className='mx-1 fade-up'>
              <div className='row justify-content-between p-3 align-items-center'>
                <div
                  className={`col-8 d-flex align-items-center  ${
                    categoryName === c.name ? 'bold' : 'text-secondary semi-bold'
                  }`}
                >
                  Advantage {getCategory(c.name, t)}
                  <span className={categoryName === c.name ? 'mb-1 ml-2' : 'd-none'}>
                    <div className={`${styles.dot} mt-1`} />
                  </span>
                </div>
                <div
                  className={`col-3 text-center ${
                    categoryName === c.name ? 'bold' : 'text-secondary'
                  }`}
                >
                  {`${c.commissionPercentage}%`}
                </div>
              </div>
              {index !== commisions.length - 1 && <div className={`${styles.line}`}></div>}
            </div>
          ))
        : SkeletonCommissions()}
      <div className='card text-secondary px-3 py-2 font-small'>
        (*) {t('commissionDescription')}
      </div>
    </div>
  );
};

export default StatusCommissions;
