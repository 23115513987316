import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../actions/associatesActions';
import { deleteCookie } from '../../../helpers/cookie';
import { TeamGroupIcon } from '../../../components/icons/TeamGroupIcon';
import { getCategory } from '../../../helpers/category';
import WhatsAppIcon from '../../../components/icons/WhatsAppIcon';
import UserIcon from '../../../components/icons/userIcon';
import LogoutIcon from '../../../components/icons/LogoutIcon';
import PaymentErrorIcon from '../../../components/icons/PaymentErrorIcon';
import ActivateConcierge from '../../../components/icons/ActivateConcierge';

import ItemMenu from './ItemMenu';
import { SecureIcon } from '../../../components/icons/SecureIcon';
import ContentApp from '../../../components/ContentApp';
import FaqIcons from '../../../components/icons/FaqIcons';

const Menu = ({ userTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const associate = useSelector((state) => state.associates);
  const user = useSelector((state) => state.associates.user);
  const isSalesTeamLead = associate?.roles?.includes('salesteamlead');
  const isAssociate = associate?.roles?.some((r) =>
    r.includes('associate', 'salesteamlead', 'bosssales'),
  );
  const phoneNumber = '3512022666';
  const message = 'Hola, necesito soporte técnico';
  let categoryName = user?.salesCategory ? getCategory(user.salesCategory.category, t) : null;

  const handleSignOut = () => {
    dispatch(logout());
    deleteCookie('selectedCity');
    deleteCookie('selectedRestaurant');
  };

  return (
    <ContentApp>
      <div className='menu-content'>
        <div className='row'>
          <div className='col pr-0 my-auto text-right'>
            <div className='user-channel font-tiny'>{userTitle}</div>
            <div className='font-large text-left lh-title'>
              {`${user?.firstName} ${user?.lastName}`}
            </div>
          </div>
        </div>
        {categoryName ? (
          <>
            <div className='light mt-1 text-capitalize'>{t('status')}</div>
            <div className='semi-bold'>Advantage {categoryName}</div>
          </>
        ) : null}
        <nav className='mt-2 pt-1'>
          <ul className='mt-4'>
            <ItemMenu
              title={t('menu.profile')}
              icon={<UserIcon color='var(--color-font-primary)' size={'15px'} />}
              href={'#profile'}
            />
            {isSalesTeamLead && (
              <>
                <ItemMenu
                  title={t('menu.activateAffiliate')}
                  icon={<ActivateConcierge color='var(--color-font-primary)' size={'15px'} />}
                  href={'#activation'}
                />
                <ItemMenu
                  title={t('menu.team')}
                  icon={<TeamGroupIcon color='var(--color-font-primary)' size={'15px'} />}
                  href={'#my-affiliates'}
                />
              </>
            )}
            {isAssociate ? (
              <ItemMenu
                title={t('menu.disputedReservations')}
                icon={<PaymentErrorIcon color='var(--color-font-primary)' size={'15px'} />}
                href={'#reservations-disputed'}
              />
            ) : null}
            <ItemMenu
              title={t('changePassword')}
              icon={<SecureIcon color='var(--color-font-primary)' size={'24px'} />}
              href={'#change-password'}
            />
            <ItemMenu
              title={t('menu.frequentQuestions')}
              icon={<FaqIcons color='var(--color-font-primary)' size={'14px'} />}
              href={`#frequent-questions`}
            />
            <ItemMenu
              title={t('menu.contactUs')}
              icon={<WhatsAppIcon color='var(--color-font-primary)' size={'14px'} />}
              href={`https://wa.me/${phoneNumber}/?text=${message}`}
            />
            {/* 
            <ItemMenu
              title={t('menu.deleteAccount')}
              icon={<UserIcon color='var(--color-font-primary)' size={'15px'} />}
              href={'#delete-account'}
            /> */}
            <ItemMenu
              title={t('menu.logout')}
              icon={<LogoutIcon color='var(--color-font-primary)' size={'15px'} />}
              onClick={handleSignOut}
              className={'mt-5 pt-3'}
            />
          </ul>
        </nav>
        <div className='font-tiny mt-5 text-center' style={{ color: 'var(--color-font-gray)' }}>
          <div className='mt-3'>
            <span className='version'>v{process.env.REACT_APP_VERSION}</span>
          </div>
        </div>
      </div>
    </ContentApp>
  );
};
export default Menu;
